@import './variables';
@import './mixins';

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font-source(false, $gray-800, $font-weight-bold);
}

h1 {
	font-size: 18px !important;
}

h2,
h3 {
	font-size: 16px !important;
}

h4,
h5,
h6 {
	font-size: 14px !important;
}

.dark-14 {
	@include font-source(14px, $indigo, 500);
}

.soft-black-14 {
	@include font-source(14px, $soft-black, $font-weight-semibold);
}

.gray3a-20 {
	@include font-source(20px, $gray-3a, $font-weight-semibold);
}

.black-14 {
	@include font-source(14px, $gray-dark);
}

.black-14-bold {
	@include font-source(14px, $gray-dark, $font-weight-bold);
}

.black-16 {
	@include font-source(16px, $gray-dark, $font-weight-bold);
}

.black-16-semibold {
	@include font-source(16px, $gray-dark, $font-weight-semibold);
}

.black-bold-16 {
	@include font-source(16px, $soft-black, $font-weight-bold);
}

.black-bold-17 {
	@include font-source(17px, $gray-dark, $font-weight-bold);
}

.black-18 {
	@include font-source(18px, $gray-dark, $font-weight-semibold);
}

.black-20 {
	@include font-source(20px, $gray-dark, $font-weight-bold);
}

.black-20-semibold {
	@include font-source(20px, $gray-dark, $font-weight-semibold);
}

.black-24 {
	@include font-source(24px, $gray-dark, $font-weight-semibold);
}

.black-28 {
	@include font-source(28px, $gray-dark, $font-weight-semibold);
}

.deepBlack-14 {
	@include font-source(14px, $deep-black, $font-weight-semibold);
}

.white-12 {
	@include font-source(12px, $white);

	&:hover {
		@include font-source(12px, $white);
	}
}

.white-12-bold {
	@include font-source(12px, $white, $font-weight-bold);
}

.white-13-semibold {
	@include font-source(13px, $white, $font-weight-semibold);
}

.white-13-bold {
	@include font-source(13px, $white, $font-weight-bold);
}

.white-14 {
	@include font-source(14px, $white);
}

.white-14-semibold {
	@include font-source(14px, $white, $font-weight-semibold);
}

.white-16-semibold {
	@include font-source(16px, $white, $font-weight-semibold);
}

.white-20-semibold {
	@include font-source(20px, $white, $font-weight-semibold);
}

.purple-14 {
	@include font-source(14px, $purple, $font-weight-semibold);
}

.purple-13 {
	@include font-source(13px, $purple, $font-weight-semibold);
}

.purple-bold-16 {
	@include font-source(16px, $purple, $font-weight-bold);
}

.purple-blue-14 {
	@include font-source(14px, $purple-blue, $font-weight-semibold);
}

.errorMessages {
	@include font-source(14px, $red, $font-weight-semibold);
}

.gray100-14 {
	@include font-source(14px, $gray-200, $font-weight-semibold);
}

.gray200-14 {
	@include font-source(14px, $gray-500, $font-weight-semibold);
}

.gray500-13 {
	@include font-source(13px, $gray-700, $font-weight-semibold);
}

.gray-600-14 {
	@include font-source(14px, $gray-600, $font-weight-semibold);
}

.gray700-12 {
	@include font-source(12px, $gray-700, $font-weight-semibold);
}

.gray700-13 {
	@include font-source(13px, $gray-700, $font-weight-semibold);
}

.gray700-13-bold {
	@include font-source(13px, $gray-700, $font-weight-bold);
}

.gray700-alt-13 {
	@include font-source(13px, $gray-700-alt, $font-weight-semibold);
}

.gray700-alt-13-bold {
	@include font-source(13px, $gray-700-alt, $font-weight-bold);
}

.gray700-14 {
	@include font-source(14px, $gray-700, $font-weight-semibold);
}

.gray800-13-bold {
	@include font-source(13px, $gray-800, $font-weight-bold);
}

.gray800-13 {
	@include font-source(13px, $gray-800, $font-weight-semibold);
}

.gray800-13-opacity {
	@include font-source(13px, $gray-800, $font-weight-semibold, null, 0.7);
}

.gray800-14-normal {
	@include font-source(14px, $gray-800);
}

.gray800-14 {
	@include font-source(14px, $gray-800, $font-weight-semibold);
}

.gray800-14-opacity {
	@include font-source(14px, $gray-800, $font-weight-semibold, null, 0.7);
}

.gray800-14-bold {
	@include font-source(14px, $gray-800, $font-weight-bold);
}

.gray800-15 {
	@include font-source(15px, $gray-800, $font-weight-semibold);
}

.gray-deep-13-bold {
	@include font-source(13px, $gray-deep, $font-weight-bold);
}

.gray-deep-14 {
	@include font-source(14px, $gray-deep, $font-weight-semibold);
}

.dark-red-semibold-20 {
	@include font-source(20px, $dark-red, $font-weight-semibold);
}

.rejected-red-semibold-14 {
	@include font-source(14px, $red-rejected, $font-weight-semibold);
}

.indigo-bold-14 {
	@include font-source(14px, $indigo, $font-weight-bold);
}
